import React, { ReactNode } from "react"

// Styling
import CSSReset from "../styles/reset"
import GlobalStyle from "../styles/"

interface IProps {
  children: ReactNode
}

const Layout = ({ children }: IProps) => (
  <>
    <CSSReset />
    <GlobalStyle />
    <main>{children}</main>
  </>
)

export default Layout
