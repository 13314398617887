// Global stylesheet
import { createGlobalStyle } from "styled-components"

// Styling
import colors from "./colors"
import textStyles from "./textStyles"
import fonts from "./fonts"

const GlobalStyle = createGlobalStyle`
  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    margin-bottom: 0;
    color: ${colors.black};
    background-color: ${colors.white};
    font-family: ${fonts.body};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    color: ${colors.red};
  }

  p {
    ${textStyles.body};
  }
`

export default GlobalStyle
