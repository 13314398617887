import { css } from "styled-components"

// Styling
import fonts from "./fonts"
import mq from "./breakpoints"

// Text styles
export default {
  pageTitle: css`
    margin: 0 0 16px;
    font-family: ${fonts.title};
    font-size: 64px;
    font-weight: 900;
    line-height: 1;

    ${mq.from.breakpoint.M`
      margin: 0 0 18px;
      font-size: 96px;
    `};
  `,
  title: css`
    margin: 0 0 32px;
    font-family: ${fonts.title};
    font-size: 48px;
    font-weight: 900;
    line-height: 1.125;

    ${mq.from.breakpoint.M`
      font-size: 56px;
      margin-bottom: 40px;
    `};
  `,
  subtitle: css`
    margin: 0 0 16px;
    font-family: ${fonts.title};
    font-size: 32px;
    font-weight: 900;
    line-height: 1.125;

    ${mq.from.breakpoint.M`
     font-size: 40px;
     margin-bottom: 24px;
   `};
  `,
  intro: css`
    margin: 0;
    font-family: ${fonts.body};
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;

    ${mq.from.breakpoint.M`
      font-size: 24px;
    `}
  `,
  body: css`
    margin: 0 0 20px;
    font-family: ${fonts.body};
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;

    ${mq.from.breakpoint.M`
      font-size: 18px;
      margin-bottom: 24px;
    `}
  `,
}
